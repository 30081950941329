/* Spinner CSS */
.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure the spinner is on top of other elements */
}

/* Disable buttons during loading */
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Chat alignment styles */
.chat-message {
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  max-width: 70%; /* Make the messages narrower */
  word-wrap: break-word; /* Ensure long words break to the next line */
}

.chat-user {
  text-align: left;
  background-color: #d9edf7; /* Blue background color */
}

.chat-assistant {
  text-align: left; /* Align text to the left */
  background-color: #d4edda; /* Green background color */
  margin-left: auto; /* Align background to the right */
  display: flex;
  justify-content: flex-end;
}

.chat-assistant .chat-message {
  text-align: left;
}

.chat-label-user,
.chat-label-ai {
  display: block;
  font-size: 0.8em;
  color: #666;
  margin-top: 5px; /* Space between the content and the label */
}

.chat-label-user {
  text-align: left;
}

.chat-label-ai {
  text-align: right;
}

/* Event alignment styles */
.event-message {
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
}

.event-type {
  font-weight: bold;
  margin-left: 10px;
}

.event-datetime {
  font-size: 0.8em;
  color: #666;
  margin-left: 10px;
}

.event-inbound {
  background-color: #d9edf7 !important; /* Same as chat-user */
}

.event-outbound {
  background-color: #d4edda !important; /* Same as chat-assistant */
}

.event-arrow {
  flex: 0 0 auto;
}

.event-content {
  flex: 1;
  text-align: center;
}

/* Existing CSS */
.App {
  text-align: center;
  background-color: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px; /* Add padding to the left and right */
}

.App-header {
  background-color: #e0e0e0;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  color: black;
  text-align: center;
  position: relative;
}

.metrics-container, .table-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 1px; /* Adds spacing between the containers */
}

.metrics-container {
  align-items: flex-start; /* Align items to the start (left) */
  text-align: left; /* Ensure the text is aligned to the left */
}

.metrics-container p {
  margin: 2px 0; /* Reduces the margin between text lines */
}

.metrics-title {
  text-align: center; /* Center the title */
  width: 100%; /* Take full width to ensure centering */
  margin-bottom: 10px;
}
.main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.metrics-section {
  flex: 1;
  margin-right: 1px; /* Adds spacing to the right */
}

.table-container {
  flex: 3;
}

.metrics-title {
  margin-bottom: 10px;
}

.table-wrapper {
  width: 100%;
  margin-top: 0px;
}

.table-hover tbody tr:hover {
  background-color: #d3d3d3;
}

.table th, .table td {
  text-align: left;
}

.modal-80w {
  max-width: 80% !important;
  width: 80% !important;
}

.modal-80h {
  max-height: 80% !important;
  height: 80% !important;
}

.modal-80p {
  max-width: 80% !important;
  width: 80% !important;
}

/* New class for 4/5 width */
.modal-4-5 {
  max-width: 64% !important; /* 4/5 of the original width (80%) */
  width: 64% !important;
}

.modal-content {
  background-color: #343a40;
  color: white;
  height: 100%;
}

.event-details-modal .modal-content {
  background-color: #f5fafc; 
}

.modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}

.modal-section {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.modal-section-attributes,
.modal-section-events {
  flex: 0.95; /* Adjust width for attributes and events */
}

.modal-section-chats {
  flex: 1.3; /* Make the chats section wider */
}

.modal-section h5 {
  margin-bottom: 10px;
}

.modal-section .item {
  margin-bottom: 5px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}

.header-row {
  display: flex;
  justify-content: flex-start;
  align-items: left;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.search-container {
  text-align: left; /* Ensure container is aligned to the left */
  width: 100%; /* Ensure container takes full width */
}

.search-form {
  display: inline-block;
}

.leads-title {
  margin-bottom: 10px;
}

/* Adjustments for Dropdowns */
.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 8px;
}

.dropdown-item-checkbox input[type="checkbox"] {
  margin-right: 8px;
}

.user-logout-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between the username and the button */
}

.username-display {
  font-size: 16px;
  font-weight: bold;
  color: black; /* You can customize this to your preference */
  text-transform: uppercase;
}

.logout-button {
  margin-left: 10px; /* Ensures space between the username and button */
  z-index: 1000;
}

